.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #707070;
  border-color: #707070;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #707070;
  padding: 15px 25px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #707070;
  border-color: #707070;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #707070;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

@media (max-width: 600px) {
  .pagination > li > a {
    padding: 5px 10px;
  }
}
