:root {
  --accent-color: #0d93ec;
}
.input-container {
  background-color: transparent;
  position: relative;
  height: 56px;
  border: 1px solid #707070;
  transition: all 300ms;
}
.input-container:focus-within {
  border: 1px solid var(--accent-color);
}

.input-container label {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  color: rgba(0, 0, 0, 0.5);
  transform-origin: left top;
  user-select: none;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    color 150ms cubic-bezier(0.4, 0, 0.2, 1), top 500ms;
}

.input-container input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  transition: border 500ms;
  padding: 20px 16px;
  font-size: 1rem;
}

.input-container input:focus {
  outline: none;
}

.input-container input:focus + label {
  color: var(--accent-color);
}

.input-container input:focus + label,
.input-container input.is-valid + label {
  transform: translateY(-160%) scale(0.75);
  background-color: #fff;
  padding: 0 6px;
  border-radius: 6px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:-webkit-autofill + label,
input:-webkit-autofill:hover + label,
input:-webkit-autofill:focus + label,
input:-webkit-autofill:active + label {
  transform: translateY(-160%) scale(0.75);
  background-color: #fff;
  padding: 0 6px;
  border-radius: 6px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="date"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}
